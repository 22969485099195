import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { firstValueFrom, take } from 'rxjs';

import { AuthenticationCookieService } from '@backend-client/authentication-cookie.service';
import { CustomTokenResponse } from '@backend-client/models/custom-token-response';
import { AuthenticationService } from '@app/modules/authentication/authentication.service';
import { ServerStatusService } from '@app/modules/shared/services/server-status.service';

@Injectable()

export class CallbackService {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private authenticationCookieService: AuthenticationCookieService,
    private serverStatusService: ServerStatusService,
    private snackBar: MatSnackBar
  ) {}

  public async parseResponse(params: URLSearchParams | { [key: string]: string | number | boolean; }, res: string): Promise<void> {
    let response = res;
    /*
      Will fail to parse if user navigates to 'callback' manually.
      they will automatically get sent back to the home page -
      this page is only to handle the Thrive Auth response!
    */
    try {
      response = decodeURIComponent(response);
      response = atob(response);
    } catch (err) {
      location.href = '/home';
      return;
    }

    try {
      // Parse state saved locally
      const localState = localStorage.getItem('dbsso');
      const parsedLocal = JSON.parse(localState);
      const localKey = Object.keys(parsedLocal)[0];
      const localParams = parsedLocal[localKey] as { expiresOn: string, redirectUrl: string };

      // Parse state returned in params using key saved locally. If it fails state may have been changed.
      const responseState = params[localKey];
      const responseParams = JSON.parse(responseState);

      if (!responseParams || responseParams.expiresOn !== localParams.expiresOn || responseParams.redirectUrl !== localParams.redirectUrl) {
        // Response state does not match locally saved state - refuse to log user in
        throw new Error('Failed to login via SSO: state issue');
      } else if (new Date(localParams.expiresOn) < new Date()) {
        // User took longer than 5 minutes to login
        throw new Error('Failed to login via SSO: request timed out - user took longer than 5 minutes to login');
      }

      // Clean up
      localStorage.removeItem('dbsso');
    } catch (err) {
      this.handleError( err ? err : 'Failed to login via SSO');
      console.error('Failed to login via SSO.', err);
      return;
    }

    await this.setToken(response);
  }

  private async setToken(response: string): Promise<void> {
    try {
      const token: CustomTokenResponse = JSON.parse(response);

      if (token) {
        // Now set the token
        await this.authenticationService.loginWithCustomToken(token);

        if (this.authenticationCookieService.getSessionCookie()) {
          // Once token is set trigger completion of SSO
          this.completeLoginSSO();

          // Subscribe ready for user being signed in
          this.serverStatusService.isServerOnline.subscribe(async () => {
            // once online (FF) and logged in close dialog and send user to home page
            if (this.serverStatusService.isOnline) {
              await this.router.navigate(['/']);
            }
          });
        }
      }
    } catch (err) {
      this.handleError('Failed to login via SSO');
      console.error('Failed to login via SSO: issue with custom token. ', err);
      return;
    }
  }

  // General error handling - provides button to retry, which takes them back to the home page.
  private handleError(message: string): void {
    this.snackBar.open(
      message, 'Retry', {
        verticalPosition: 'bottom',
        duration: 0
      }
    );

    // Clean up
    localStorage.removeItem('dbsso');

    // Send back to home page to retry
    this.snackBar._openedSnackBarRef.onAction().pipe(take(1)).subscribe(() => {
      location.href = '/home';
    });
  }

  private async completeLoginSSO(): Promise<void> {
    // test if we are logged in
    await this.authenticationService.setUserScopes(true);
    const scopes = await firstValueFrom(this.authenticationService.getUserScopes$());

    if (scopes.length > 0) {
      // we are logged in
      this.authenticationService.finishLoginSSO();
    } else {
      // we are not logged in
      this.handleError('Failed to login via SSO');
      console.error('Failed to login via SSO: no scopes returned.');
      return;
    }
  }
}
