import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CallbackService } from './callback.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html'
})
export class CallbackComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private callbackService: CallbackService,
  ) {
   }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(async params => {
      await this.callbackService.parseResponse(params, params.response);
    });
  }
}
