import { Injectable } from '@angular/core';

import { BehaviorSubject, firstValueFrom, fromEvent, Subject } from 'rxjs';

import { PatientTimelineService, TherapistService } from '@backend-client/services';
import { EnvironmentService } from '../environment.service';
import { MessageService } from '../message.service';
import { GoogleDriveApiError } from '../../models/google-drive-api-error.model';
import { GoogleDrivePermissionLevel } from './google-drive-permissions.enum';
import { UploadStages } from './google-drive-upload-stages.enum';

@Injectable({
  providedIn: 'root'
})

export class GoogleDriveUploadService {
  public UPLOAD_SCOPES = this.environmentService.getEnvironment().google.uploadScope;
  public fileUploadComplete$ = new Subject<void>();
  public uploadingFileMoment$ = new BehaviorSubject<boolean>(false);

  public set patientId(id: string) {
    this._patientId = id;
    this.getPatientFolderId();
  }
  public get patientId(): string {
    return this._patientId;
  }

  private _patientId: string;
  private patientFolderId: string;
  private uploadingLength: number;
  // Keep track of upload status
  private progressStatus: UploadStages;
  private apiResponse: google.picker.ResponseObject;
  private filesToUpload: google.picker.DocumentObject[];
  private fileIdBeingUploaded: string;
  private uploadResponse: gapi.client.Response<gapi.client.drive.File>;

  private get apiKey(): string {
    return this.environmentService.getEnvironment().google.apiKey;
  }
  private get appId(): string {
    return this.environmentService.getEnvironment().google.appId;
  }

  constructor(
    private environmentService: EnvironmentService,
    private messageService: MessageService,
    private therapistService: TherapistService,
    private patientTimelineService: PatientTimelineService
  ) {
    fromEvent(window, 'online').subscribe(() => {
      // This fires if the app has been offline
      if (this.progressStatus) {
        this.recoverIfGoesOffline();
      }
    });
  }

  public createPicker(accessToken: string): void {
    this.progressStatus = UploadStages.awaitingFile;

    const docsView = new google.picker.DocsView(google.picker.ViewId.DOCS)
      .setIncludeFolders(true)
      .setMode(google.picker.DocsViewMode.LIST);

    const picker = new google.picker.PickerBuilder()
      .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
      .enableFeature(google.picker.Feature.MINE_ONLY)
      .setDeveloperKey(this.apiKey)
      .setAppId(this.appId)
      .setOAuthToken(accessToken)
      .addView(docsView)
      .addView(new google.picker.DocsUploadView())
      .setCallback((data: google.picker.ResponseObject) => this.pickerCallback(data))
      .build();

    picker.setVisible(true);
  }

  private async pickerCallback(data: google.picker.ResponseObject): Promise<void> {
    if (data.action === google.picker.Action.PICKED) {
      this.progressStatus = UploadStages.filePicked;
      this.apiResponse = data;
      // Set loading spinner
      this.uploadingFileMoment$.next(true);

      const document = data[google.picker.Response.DOCUMENTS];
      this.filesToUpload = document; // store data incase we go offline
      this.uploadingLength = document.length;

      for (const doc of document) {
        this.fileIdBeingUploaded = doc[google.picker.Document.ID]; // store data incase we go offline
        await this.getGoogleDriveFileDetails(doc[google.picker.Document.ID]);
      }
    } else if (data.action === google.picker.Action.CANCEL) {
      await this.downgradeDrivePermissions();
    }
  }

  private async getGoogleDriveFileDetails(fileId: string): Promise<void> {
    await gapi.client.drive.files.get({
      fileId: fileId,
      fields: '*',
      supportsAllDrives: true,
      supportsTeamDrives: true,
    }).then((res: gapi.client.Response<gapi.client.drive.File>) => {
      this.progressStatus = UploadStages.fetchedFileDetails;
      this.uploadResponse = res; // store data incase we go offline

      this.updateGoogleDriveFile(fileId, res);
    }).catch((error: GoogleDriveApiError) => {
      const errorMessage = error.status === 404 ? `${error.result.error.code}: Unfortunately you do not have access to the Google Drive Folder` : `${error.result.error.code}: ${error.result.error.message}`;
      this.messageService.showMessage(errorMessage);
      console.error(error);
      // Remove loading spinner
      this.fileUploadComplete$.next();
    });
  }

  private updateGoogleDriveFile(fileId: string, response: gapi.client.Response<gapi.client.drive.File>): void {
    const contentRestriction = {
      'readOnly': true,
      'reason': 'File ready to be uploaded via Dashboard',
    };

    // Sends selected file to Shared Drive Folder
    gapi.client.drive.files.update({
      fileId: fileId,
      supportsAllDrives: true,
      supportsTeamDrives: true,
      addParents: this.patientFolderId,
      removeParents: response.result.parents[0],
      resource: {
        contentRestrictions: [contentRestriction],
        copyRequiresWriterPermission: true
      },
    }).then(async (res: gapi.client.Response<gapi.client.drive.File>) => {
      this.progressStatus = UploadStages.updatedFile;
      this.uploadResponse = res; // store data incase we go offline
      await this.createGoogleFileMoment(res);
    }).catch((error: GoogleDriveApiError) => {
      const errorMessage = error.status === 404 ? `${error.result.error.code}: Unfortunately you do not have access to the Google Drive Folder` : `${error.result.error.code}: ${error.result.error.message}`;
      this.messageService.showMessage(errorMessage);
      console.error(error);
      // Remove loading spinner
      this.fileUploadComplete$.next();
    });
  }

  private async createGoogleFileMoment(response: gapi.client.Response<gapi.client.drive.File>): Promise<void> {
    await firstValueFrom(this.patientTimelineService.PatientsMomentsCreatePatientDriveMoment({
      patientId: this._patientId,
      momentBody: {
        googleDriveFileId: response.result.id,
        googleDriveFolderId: response.result.driveId,
        fileName: response.result.name,
        contentType: response.result.mimeType
      }
    })).then(async () => {
      // Remove 1 from the length as completed
      this.uploadingLength--;

      this.progressStatus = UploadStages.createdMoment;

      // Check we have completed uploading all before revoking permissions
      if (this.uploadingLength === 0) {
        await this.downgradeDrivePermissions();
        this.progressStatus = UploadStages.awaitingFile;
      }

      // Trigger UI update regularly so Therapist knows the uploads are working.
      this.fileUploadComplete$.next();
    }).catch((error: GoogleDriveApiError) => {
      this.messageService.showMessage('Error encountered creating moment for patient. Please try again');
      console.error('Error encountered creating moment for patient. - File has been uploaded successfully. ' + error);

      // Trigger UI update regularly so Therapist knows the uploads are working.
      this.fileUploadComplete$.next();
    });
  }

  private async getPatientFolderId(): Promise<void> {
    this.patientFolderId = await firstValueFrom(this.patientTimelineService.PatientsMomentsGetPatientFolderForGoogleDrive(this._patientId));
  }

  private async downgradeDrivePermissions(): Promise<void> {
    this.progressStatus = UploadStages.downgradePermission;
    return await firstValueFrom(this.therapistService.TherapistEnsureGoogleDriveAccountHasPermission(GoogleDrivePermissionLevel.Viewer));
  }

  private async recoverIfGoesOffline(): Promise<void> {
    switch(this.progressStatus) {
      case UploadStages.awaitingFile:
        // Nothing we can do
        break;
      case UploadStages.filePicked:
        this.pickerCallback(this.apiResponse);
        break;
      case UploadStages.fetchedFileDetails:
        this.updateGoogleDriveFile(this.fileIdBeingUploaded, this.uploadResponse);

        break;
      case UploadStages.updatedFile:
        await this.createGoogleFileMoment(this.uploadResponse);
        break;
      case UploadStages.createdMoment:
      case UploadStages.downgradePermission:

        if (this.uploadingLength === 0) {
          await this.downgradeDrivePermissions();
          this.resetProgressStatus();
          // Trigger UI update regularly so Therapist knows the uploads are working.
          this.fileUploadComplete$.next();
          break;
        }

        this.filesToUpload = this.filesToUpload.filter(file => file[google.picker.Document.ID] !== this.fileIdBeingUploaded);

        // Carry on iterating through
        for (const doc of this.filesToUpload) {
          this.fileIdBeingUploaded = doc[google.picker.Document.ID];
          await this.getGoogleDriveFileDetails(doc[google.picker.Document.ID]);
        }
      break;
    }
  }

  private resetProgressStatus(): void {
    // Reset status tracking vars as files completed
    this.apiResponse = undefined;
    this.uploadResponse = undefined;
    this.fileIdBeingUploaded = undefined;
    this.progressStatus = UploadStages.awaitingFile;
  }
}
