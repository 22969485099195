// Google Docs - https://developers.google.com/drive/picker/guides/sample

import { Injectable } from '@angular/core';

import * as jose from 'jose';
import { firstValueFrom } from 'rxjs';

import { TherapistService } from '@backend-client/services';
import { TubTherapistGoogleDriveAccount } from '@backend-client/models';
import { EnvironmentService } from '../environment.service';
import { MessageService } from '../message.service';
import { GoogleDriveUploadService } from './google-drive-upload.service';
import { GoogleDriveDownloadService } from './google-drive-download.service';
import { GoogleDrivePermissionLevel } from './google-drive-permissions.enum';
import { GoogleDriveActions } from './google-drive-actions.enum';
import { GoogleDrivePreviewService } from './google-drive-preview.service';

@Injectable({
  providedIn: 'root',
})
export class GoogleDriveService {
  public googleUserEmail: string;

  public get apiKey(): string {
    return this.environmentService.getEnvironment().google.apiKey;
  }

  private googleUserId: string = undefined;
  private accessTokenUpload: string = undefined;
  private tokenClientUpload: google.accounts.oauth2.TokenClient;
  private tokenClientDownload: google.accounts.oauth2.TokenClient;
  private fedCMError = false;

  private TIMEOUT_DELAY = 800; // Wait 800ms to allow login window to close

  constructor(
    private environmentService: EnvironmentService,
    private messageService: MessageService,
    private therapistService: TherapistService,
    private googleDriveUploadService: GoogleDriveUploadService,
    private googleDrivePreviewService: GoogleDrivePreviewService,
    private googleDriveDownloadService: GoogleDriveDownloadService
  ) {}

  /*
   * GENERAL CALLS
   *
   * Called on log out or unlinking of Google Account
   * Revoke access token for Google Login
   */
  public revokeAccessTokenConsent(): void {
    google.accounts.id.disableAutoSelect();

    if (this.accessTokenUpload) {
      google.accounts.oauth2.revoke(this.accessTokenUpload, () => {
        this.accessTokenUpload = undefined;
      });
    }

    // Wipe all validators
    this.googleUserEmail = undefined;
    this.googleUserId = undefined;
    this.tokenClientUpload = undefined;
    this.tokenClientDownload = undefined;
  }

  /*
   * PREVIEW Flow
   *
   * Order called (not logged in):
   * initToken
   * initAccount
   * promptLogin
   * handleCredentialResponse
   * saveTherapistGoogleAccountDetails
   * requestPermissionChange
   * requestAccessToDriveFolder
   * handleAuthClick
   */
  public checkUserHasAccessToFolder(): boolean {
    if (!this.googleUserEmail) {
      this.initTokenPreview(GoogleDriveActions.Preview);
    }

    return !!this.googleUserEmail;
  }

  /*
   * UPLOAD Flow
   *
   * Order called (not logged in):
   * initToken
   * initAccount
   * checkTherapistForAccountToken
   * requestAccessToDriveFolder
   * promptRequestToken
   * promptLogin
   * handleCredentialResponse
   * saveTherapistGoogleAccountDetails
   * requestPermissionChange
   * requestAccessToDriveFolder
   * handleAuthClick
   * gapiLibraryLoadedUpload
   *
   * Order called (logged in):
   * initToken
   * initAccount
   * checkTherapistForAccountToken
   * requestAccessToDriveFolder
   * promptRequestToken
   * handleAuthClick
   * gapiLibraryLoadedUpload
   */

  /**
   * Initalise Google Services to upload a file
   * @param patientId
   */
  // UPLOAD AND PREVIEW
  public async initTokenUpload(action: GoogleDriveActions, patientId: string): Promise<void> {
    // This is done to make sure patient ID is passed in
    await this.initToken(action, patientId);
  }

  public async initTokenPreview(action: GoogleDriveActions): Promise<void> {
    await this.initToken(action);
  }
  // Shared method
  public async initToken(action: GoogleDriveActions, patientId?: string): Promise<void> {
    this.initAccount(action);

    this.tokenClientUpload = google.accounts.oauth2.initTokenClient({
      client_id: this.environmentService.getEnvironment().google.clientId,
      scope: this.googleDriveUploadService.UPLOAD_SCOPES,
      prompt: (this.googleUserEmail && this.googleUserId) || this.tokenClientUpload ? 'none' : 'consent',
      callback: (response: google.accounts.oauth2.TokenResponse) => {
        // Access denied error
        if (response?.error !== undefined) {
          this.tokenClientUpload = undefined;
          this.googleUserEmail = undefined;
          this.googleUserId = undefined;
          console.error(response);

          if (response.error === 'interaction_required') {
            // Use set timeout to allow window to close first, then reopen to prompt user to select account
            setTimeout(() => this.initTokenUpload(GoogleDriveActions.Upload, patientId), this.TIMEOUT_DELAY);
          }
          return;
        }
        this.accessTokenUpload = response?.access_token;

        if (this.googleUserEmail) {
          this.handleAuthClick(action);
        } else {
          if (this.fedCMError) {
            this.messageService.showMessage('FedCM error - please reset browser permissions');
          } else {
            this.promptLogin();
          }
        }
      },
      error_callback: (response: google.accounts.oauth2.ClientConfigError) => {
        if (response.type === 'popup_closed' || response.type === 'popup_failed_to_open') {
          this.tokenClientUpload = undefined;
          this.googleUserEmail = undefined;
          this.googleUserId = undefined;

          this.requestPermissionChange(GoogleDrivePermissionLevel.Viewer);

          if (response.type === 'popup_failed_to_open') {
            this.messageService.showMessage('Failed to open Google One Tap, please reset browser permissions');
          }
          console.error(response);
          return;
        }

        console.error(response);
        throw response;
      }
    });

    await this.checkTherapistForAccountToken(action);
    this.promptRequestToken();

    if (action === GoogleDriveActions.Upload) {
      this.googleDriveUploadService.patientId = patientId;
      this.gapiLibraryLoadedUpload();
    }
  }

  /*
   * DOWNLOAD Flow
   *
   * Order called:
   * initTokenDownload
   * gapiLibraryLoadedDownload
   * initializeGapiLibrary
   * initGoogleDriveApi
   * promptRequestToken
   * handleAuthClick
   */

  /**
   * Initalise Google Services to upload a download
   * @param patientId
   */
  public initTokenDownload(): void {
    this.tokenClientDownload = google.accounts.oauth2.initTokenClient({
      client_id: this.environmentService.getEnvironment().google.clientId,
      scope: this.googleDriveDownloadService.DOWNLOAD_SCOPE,
      prompt: (this.googleUserEmail && this.googleUserId) || this.tokenClientDownload ? 'none' : 'consent',
      callback: (response: google.accounts.oauth2.TokenResponse) => {
        // Access denied error
        if (response?.error !== undefined) {
          this.tokenClientDownload = undefined;
          this.googleUserEmail = undefined;
          this.googleUserId = undefined;
          console.error(response);

          if (response.error === 'interaction_required') {
            // Use set timeout to allow window to close first, then reopen to prompt user to select account
            setTimeout(() => this.initTokenDownload(), this.TIMEOUT_DELAY);
          }
          return;
        }
        this.handleAuthClick(GoogleDriveActions.Download);
      },
      error_callback: (response: google.accounts.oauth2.ClientConfigError) => {
        if (response.type === 'popup_closed' || response.type === 'popup_failed_to_open') {
          this.tokenClientDownload = undefined;
          this.googleUserEmail = undefined;
          this.googleUserId = undefined;

          if (response.type === 'popup_failed_to_open') {
            this.messageService.showMessage('Failed to open Google One Tap, please reset browser permissions');
          }
          console.error(response);
          return;
        }

        console.error(response);
        throw response;
      }
    });

    this.promptRequestToken(true);
    this.gapiLibraryLoadedDownload();
  }

  /*
   * SHARED METHODS
   */

  /**
   * Initialise the Google Accounts ID API - this returns us the Google Account information
   * @param action
   * @param googleAccount
   */
  // UPLOAD AND PREVIEW
  public initAccount(action: GoogleDriveActions): void {
    google.accounts.id.initialize({
      client_id: this.environmentService.getEnvironment().google.clientId,
      auto_select: true,
      cancel_on_tap_outside: false,
      login_hint: this.googleUserEmail || '',
      use_fedcm_for_prompt: true,
      hd: '*', // Workspace accounts shown only
      callback: async (response: google.accounts.id.CredentialResponse) => {
        await this.handleCredentialResponse(response, action);
        this.handleAuthClick(action);
      }
    });
  }

  // Triggers login to Google Account ID - this returns us Google Account details
  // UPLOAD (not logged in) and PREVIEW (not logged in)
  public promptLogin(): void {
    google.accounts.id.prompt((notification) => {
      if (notification.isSkippedMoment()) {
        this.fedCMError = true;
        this.messageService.showMessage('FedCM error - please reset browser permissions');
        console.error('User clicked "x" on Google One Tap - Entering cooldown period which suppresses the One Tap prompt from displaying temporarily. In Chrome, you can reset the cooldown status by clicking the lock icon in the address bar and clicking the Reset Permission button.');
      }
    });
  }

  // Loads the discovery doc to initialize the API for download functionality.
  // UPLOAD and DOWNLOAD
  private async initGoogleDriveApi(action: GoogleDriveActions): Promise<void> {
    await gapi.client.init({
      apiKey: this.apiKey,
      discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
      scope:
        action === GoogleDriveActions.Download
          ? this.googleDriveDownloadService.DOWNLOAD_SCOPE
          : this.googleDriveUploadService.UPLOAD_SCOPES,
    });
  }

  // User signed in... perform action
  // UPLOAD, PREVIEW (not logged in) and DOWNLOAD
  public handleAuthClick(action: GoogleDriveActions): void {
    switch (action) {
      case GoogleDriveActions.Download:
        /* Download file */
        this.googleDriveDownloadService.checkScopeThenDownloadFile();
        break;
      case GoogleDriveActions.Upload:
        /* Upload file */
        if (this.googleDriveUploadService.patientId) {
          this.googleDriveUploadService.createPicker(this.accessTokenUpload);
        }
        break;
      case GoogleDriveActions.Preview:
        /* Preview file */
        if (this.googleDrivePreviewService.googleFileMoment) {
          this.googleDrivePreviewService.previewFile();
        }
        break;
      default:
        break;
    }
  }

  // Assign details returned about Google Account to variables, call to save data on therapist object
  // UPLOAD and PREVIEW (not logged in)
  private async handleCredentialResponse(
    response: google.accounts.id.CredentialResponse,
    action: GoogleDriveActions,
  ): Promise<void> {
    try {
      const responsePayload = jose.decodeJwt(response.credential);

      this.googleUserId = responsePayload.sub;
      this.googleUserEmail = responsePayload?.email as string;

      if (responsePayload?.email) {
        await this.saveTherapistGoogleAccountDetails(
          (action === GoogleDriveActions.Upload
            ? GoogleDrivePermissionLevel.Contributor
            : GoogleDrivePermissionLevel.Viewer),
          action === GoogleDriveActions.Download
        );
      }
    } catch (error) {
      this.messageService.showMessageAndClose('Unable to parse response - login to Google Account Failed');
      console.error('Unable to parse response - login to Google Account Failed. ', error);
    }
  }

  // If therapist object contains Google email - request access to Google Drive folder
  private async checkTherapistForAccountToken(action: GoogleDriveActions): Promise<void> {
    try {
      if (!this.googleUserEmail) {
        await this.getTherapistGoogleAccount().then(async account => {
          if (account?.googleDriveEmail) {
            this.googleUserEmail = account.googleDriveEmail;
            this.googleUserId = account.googleDriveUserId;
            await this.requestAccessToDriveFolder(action === GoogleDriveActions.Upload
              ? GoogleDrivePermissionLevel.Contributor
              : GoogleDrivePermissionLevel.Viewer);
          }
        });
      } else {
        await this.requestAccessToDriveFolder(action === GoogleDriveActions.Upload
          ? GoogleDrivePermissionLevel.Contributor
          : GoogleDrivePermissionLevel.Viewer);
      }
    } catch (error) {
      console.error(error);
      this.messageService.showMessage(error.message);
    }
  }

  // Fetch Therapist Google Account details
  private async getTherapistGoogleAccount(): Promise<TubTherapistGoogleDriveAccount> {
    return await firstValueFrom(this.therapistService.TherapistGetGoogleDriveAccount());
  }

  // Request permission to access Shared Google Drive folder
  // UPLOAD (not logged in) and PREVIEW (not logged in)
  private async requestAccessToDriveFolder(permissionLevel: GoogleDrivePermissionLevel): Promise<void> {
    return await firstValueFrom(this.therapistService.TherapistEnsureGoogleDriveAccountHasPermission(permissionLevel));
  }

  // Save Google Account details to Therapist object.
  private async saveTherapistGoogleAccountDetails(role: GoogleDrivePermissionLevel, skipPermissionChange: boolean = false): Promise<void> {
    return await firstValueFrom(this.therapistService.TherapistAddGoogleDriveAccount({ googleDriveEmail: this.googleUserEmail }))
      .then(async () => {
        if (this.googleUserEmail && !skipPermissionChange) {
          await this.requestPermissionChange(role);
        }
      })
      .catch(error => {
        this.messageService.showMessageAndClose('Unable to save Google Account details to your Therapist account.');
        console.error('Unable to save Google Account details to your Therapist account.', error);
      });
  }

  // Request Permissions to the shared drive as a reader (viewer) or writer (contributor)
  private async requestPermissionChange(permissionLevel: GoogleDrivePermissionLevel): Promise<void> {
    return await firstValueFrom(this.therapistService.TherapistEnsureGoogleDriveAccountHasPermission(permissionLevel))
      .catch(
        error => {
          if (error.status === 400) {
            console.error(`User doesn't exist on Shared Google Drive Folder, please login first.`, error);
          } else if (error.status === 404) {
            this.messageService.showMessageAndClose('Failed to find Therapist account details.');
            console.error('Failed to find Therapist account details, for Google Drive Folder Permissions.', error);
          } else {
            this.messageService.showMessageAndClose('Failed to provide permission to Google Drive Folder. Please retry logging into your Google Account.');
            console.error('Failed to provide permission to Google Drive Folder. ', error);
          }
        },
      );
  }

  // Triggers consent form to get access token for uploading files
  // UPLOAD and DOWNLOAD
  private promptRequestToken(download = false): void {
    if (download) {
      this.tokenClientDownload.requestAccessToken();
    } else {
      this.tokenClientUpload.requestAccessToken();
    }
  }

  /*
   * UPLOAD ONLY - PRIVATE
   *
   */

  // Loads picker into Google API
  private gapiLibraryLoadedUpload(): void {
    gapi.load('client:picker', this.initializeGapiUpload.bind(this));
  }

  // Callback after the API client is loaded. Calls to load the discovery doc & initialize the API.
  private initializeGapiUpload(): void {
    this.initGoogleDriveApi(GoogleDriveActions.Upload);
  }

  /*
   * DOWNLOAD ONLY - PRIVATE
   *
   */

  // Callback after api.js to load client into Google API
  private gapiLibraryLoadedDownload(): void {
    gapi.load('client', this.initializeGapiDownload.bind(this));
  }

  // Callback after the API client is loaded. Calls to load the discovery doc & initialize the API.
  private initializeGapiDownload(): void {
    this.initGoogleDriveApi(GoogleDriveActions.Download);
  }
}
